
export default {
  layout: 'default-without-footer',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    console.error('error', this.error);
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
};
