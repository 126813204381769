import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e63ce570 = () => interopDefault(import('../src/pages/market/index.vue' /* webpackChunkName: "pages/market/index" */))
const _ea6a0942 = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _fdfeb560 = () => interopDefault(import('../src/pages/sale.vue' /* webpackChunkName: "pages/sale" */))
const _31bf529f = () => interopDefault(import('../src/pages/tablo.vue' /* webpackChunkName: "pages/tablo" */))
const _f77c2e7c = () => interopDefault(import('../src/pages/passengers/feedback.vue' /* webpackChunkName: "pages/passengers/feedback" */))
const _4bc09f16 = () => interopDefault(import('../src/pages/passengers/refund.vue' /* webpackChunkName: "pages/passengers/refund" */))
const _15620238 = () => interopDefault(import('../src/pages/passengers/search-ticket.vue' /* webpackChunkName: "pages/passengers/search-ticket" */))
const _77973299 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6a7b41c = () => interopDefault(import('../src/pages/passengers/_.vue' /* webpackChunkName: "pages/passengers/_" */))
const _2aa406ec = () => interopDefault(import('../src/pages/news/_.vue' /* webpackChunkName: "pages/news/_" */))
const _e0cc1556 = () => interopDefault(import('../src/pages/market/_.vue' /* webpackChunkName: "pages/market/_" */))
const _855e29b4 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/market",
    component: _e63ce570,
    meta: {"name":"market-list-page"},
    name: "market"
  }, {
    path: "/news",
    component: _ea6a0942,
    meta: {"name":"news-list-page"},
    name: "news"
  }, {
    path: "/sale",
    component: _fdfeb560,
    meta: {"name":"sale","layout":"affiliator"},
    name: "sale"
  }, {
    path: "/tablo",
    component: _31bf529f,
    meta: {"name":"tablo-page","layout":"half-blue"},
    name: "tablo"
  }, {
    path: "/passengers/feedback",
    component: _f77c2e7c,
    meta: {"name":"feedback-form"},
    name: "passengers-feedback"
  }, {
    path: "/passengers/refund",
    component: _4bc09f16,
    meta: {"name":"refund-page"},
    name: "passengers-refund"
  }, {
    path: "/passengers/search-ticket",
    component: _15620238,
    meta: {"name":"search-page"},
    name: "passengers-search-ticket"
  }, {
    path: "/",
    component: _77973299,
    meta: {"name":"index-page","layout":"index"},
    name: "index"
  }, {
    path: "/passengers/*",
    component: _f6a7b41c,
    meta: {"name":"passengers-page"},
    name: "passengers-all"
  }, {
    path: "/news/*",
    component: _2aa406ec,
    meta: {"name":"news-page"},
    name: "news-all"
  }, {
    path: "/market/*",
    component: _e0cc1556,
    meta: {"name":"market-page"},
    name: "market-all"
  }, {
    path: "/*",
    component: _855e29b4,
    meta: {"name":"headless-page"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
