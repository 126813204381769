import Vue from 'vue';
import Asb3Sender from 'razlet-sdk/lib/api/asb3-sender';
// import createApiSender from '~/api/sender';
import Sender from 'razlet-sulu-sdk/lib/api/sender';
import {sendError} from '~/utils/error';
// import TabloList from '~/entities/tablo-list';

const state = () => ({
  ua: /iPhone|iPad|iPod|Android|Windows Phone/.test(typeof window !== 'undefined' ? window.navigator.userAgent : ''),
  mobile: false,
  offline: false,
  host: null,
});

const mutations = {
  setMobile(state, value) {
    state.mobile = value;
  },
  setOffline: (state, value) => {
    state.offline = value;
  },
};

const getters = {
  isMobile(state) {
    return state.mobile;
  },
  isOffline(state) {
    return state.offline;
  },
};

const actions = {
  async nuxtServerInit ({ dispatch }, {app, req, isDev}) {
    const currentLang = app.$cookiz.get('defaultLanguage') || 'ru';
    Vue.prototype.$locale = currentLang;
    await app.i18n.setLocale(currentLang);
    await dispatch('sulu/initSuluSender', {req, isDev, requests: ['getMenu', 'airport/getAbout', 'airport/getSitesList', 'airport/getWeather']});
  },
  // async getTablo({state}, req) {
  //   console.log(state.host);
  //   const apiSender = createApiSender(state.host);
  //   const about = state.getters['sulu/airport/about'];
  //   const response = await apiSender.get('/asb/flights', { params: req.buildRequest(about.code) })
  //     .then(res => {
  //       return Promise.resolve(new TabloList(res.data, req.timeOptions, req.direction.altValue));
  //     })
  //     .catch(e => {
  //       sendError(e);
  //     });
  //   return response;
  // },
  // eslint-disable-next-line no-empty-pattern
  async getTabloWeather({}, req) {
    const asb3Sender = new Asb3Sender();
    const response = await asb3Sender.get(`/api/web/schedule/weather?city=${req.city}&dateTime=${req.date}`, null)
      .then(res => {
        return Promise.resolve(
          res.data?.weather?.length && res.data?.main?.temp
            ? { icon: res.data.weather[0].icon, temp: res.data.main.temp }
            : { icon: '', temp: '' }
        );
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async sendFeedback({state}, data) {
    const sender = new Sender({ baseUrl: `${state.sulu.host}/${Vue.prototype.$locale}/api` });
    const response = await sender.post('/feedback/send', data);

    if (response.status !== 200) {
      return Promise.reject(response.data);
    }

    return response;
  },
};

export default {
  strict: false,
  state,
  getters,
  mutations,
  actions,
};